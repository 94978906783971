//  Save draft to local storage
//
//  Supports:
// - plain HTML inputs
// - TinyMCE editors     (@tinymce is set)
// - ACE editors         (@ace is set)
// - Prosemirror editors (@prosemirror is set)

import $ from 'jquery'
import { get, set, del } from 'idb-keyval'
import { dateFormat } from '../helpers/date_format'

export class LocalDraftManager extends window.SimpleroManager {
  constructor() {
    super(...arguments)
    this.storage_key = this.elm.data('local-draft-storage-key')
    this.last_update = this.elm.data('last-updated-at')
    this.saving_drafts = this.canSave()
    this.form = this.elm.closest('form')
    this.format_time_24h = this.elm.parents('body').data('time-format-24h')
    this.hide_status_word = this.elm.data('hide-status-word')

    $(document).on(
      'click',
      "a[data-behavior='clear-local-storage']",
      this.clearLocalStorage
    )
    this.form.on('click', 'a.cancel', this.clearLocalStorage)
    this.form.on('submit', this.onFormSubmit)
    this.time_format = '%l:%M:%S %p'
    if (this.format_time_24h) {
      this.time_format = ' %H:%M:%S'
    }
    if (
      this.form[0] != null &&
      !this.elm.hasClass('tinymce') &&
      !this.elm.hasClass('ace-editor') &&
      !this.elm.hasClass('tiptap')
    ) {
      this.onInputLoaded()
    }
  }

  canSave = () => {
    if (window.indexedDB && this.storage_key) {
      return true
    } else {
      return false
    }
  }

  buildStatus = () => {
    let status_container_selector, template
    status_container_selector = this.elm.data('local-draft-status')
    this.status = $(
      this.ace
        ? this.templates.status_ace
        : (template = this.elm.data('local-draft-status-template')) &&
          this.templates[template]
        ? this.templates[template]
        : this.templates.status
    )
    if (!this.hide_status_word) {
      this.status_words = this.status.find('.words')
    }
    this.status_draft = this.status.find('.draft')
    if (status_container_selector) {
      $(document).on(
        'click',
        `${status_container_selector} .draft a.discard`,
        this.discardRestoredDraft.bind(this)
      )
    } else {
      this.status_draft.on(
        'click',
        'a.discard',
        this.discardRestoredDraft.bind(this)
      )
    }

    this.status_draft.on('click', 'a.restore', this.forceRestore.bind(this))
    if (status_container_selector) {
      return (this.status_container = this.form.find(status_container_selector))
    }
  }

  unsetTinyMCE = () => {
    return (this.tinymce = null)
  }

  unsetProseMirror = () => {
    return (this.prosemirror = null)
  }

  onInputLoaded = () => {
    this.last_val = this.elm.val()
    this.elm.on('keyup keydown keypress', this.onChange)
    this.buildStatus()
    this.elm.siblings('.text-editor-meta').remove()
    if (this.status_container) {
      this.status_container.innerHTML = ''
      this.status.appendTo(this.status_container)
    } else {
      this.status.insertAfter(this.elm)
    }
    return this.onLoaded()
  }

  onTinyMCELoaded = (tinymce) => {
    this.tinymce = tinymce
    this.tinymce.on('change', this.onChange)
    this.tinymce.on('keyup keydown keypress', this.onChange)
    this.buildStatus()
    this.elm.siblings('.text-editor-meta').remove()
    this.status.insertAfter(this.elm)
    return this.onLoaded()
  }

  onProseMirrorLoaded = (proseMirrorProxy) => {
    this.prosemirror = proseMirrorProxy
    this.prosemirror.on('change', this.onChange)
    this.buildStatus()
    this.elm.siblings('.text-editor-meta').remove()
    this.status.insertAfter(this.elm)
    return this.onLoaded()
  }

  onACELoaded = (ace) => {
    this.ace = ace
    this.ace.on('change', this.onChange)
    this.buildStatus()
    if (this.status_container) {
      this.status_container.find('.text-editor-meta').remove()
      this.status.appendTo(this.status_container)
    } else {
      this.status.insertBefore(this.elm)
    }
    return this.onLoaded()
  }

  onTiptapLoaded = (tiptap) => {
    this.tiptap = tiptap
    this.buildStatus()
    if (this.status_container) {
      this.status_container.innerHTML = ''
      this.status_container.append(this.status)
    } else {
      this.elm.siblings('.text-editor-meta').remove()
      this.status.insertAfter(this.elm)
    }
    return this.onLoaded()
  }

  getValue = () => {
    switch (false) {
      case this.tinymce == null:
        return this.tinymce.getContent({
          format: 'raw',
        })
      case this.ace == null:
        return this.ace.getValue()
      case this.prosemirror == null:
        return this.prosemirror.getValue()
      case this.tiptap == null:
        return this.tiptap.getHTML()
      default:
        return this.elm.val()
    }
  }

  getTextValue = () => {
    switch (false) {
      case this.tinymce == null:
        return this.tinymce.getContent({
          format: 'text',
        })
      default:
        return this.getValue()
    }
  }

  setValue = (value) => {
    switch (false) {
      case this.tinymce == null:
        this.tinymce.setContent(value, {
          fromDraftManager: true,
        })
        break
      case this.ace == null:
        this.ace.setValue(value, -1)
        break
      case this.prosemirror == null:
        this.prosemirror.setValue(value)
        break
      case this.tiptap == null:
        this.tiptap.chain().setContent(value, { emitUpdate: true }).run()
        break
      default:
        this.elm.val(value)
    }
    return (this.last_val = value)
  }

  onLoaded = async () => {
    const value = await this.getSavedDraft()
    if (value && value !== '<p></p>') {
      this.restoreDraftToEditor(value)
    }
    return this.updateWordCount()
  }

  onChange = () => {
    var cur_val
    cur_val = this.getValue()
    if (cur_val !== this.last_val) {
      this.last_val = cur_val
      return this.contentChanged()
    }
  }

  contentChanged = () => {
    this.updateWordCount()
    return this.saveDraftToLocalStorage()
  }

  onFormSubmit = () => {
    if (!this.status_draft) {
      this.buildStatus()
    }
    this.saveDraftToLocalStorage()
    return $('<input>')
      .attr({
        type: 'hidden',
        name: '_draft_manager_storage_key[]',
      })
      .val(this.storage_key)
      .appendTo(this.form)
  }

  saveDraftToLocalStorage = (e) => {
    if (!this.saving_drafts) {
      return
    }
    const value = this.getValue()
    if (value === '<p></p>') {
      return
    }
    this.updateDraftStatusMessage('. Saving&hellip;')
    set(
      this.storage_key,
      JSON.stringify({
        content: value,
        timestamp: new Date(),
      })
    )
    return this.updateDraftStatusMessage(
      this.templateValue('draft', {
        saved_at: dateFormat(this.time_format, new Date()),
      }),
      null,
      'draft'
    )
  }

  clearLocalStorage = () => {
    this.saving_drafts = this.canSave()
    if (window.indexedDB) {
      del(this.storage_key)
    }
    return true
  }

  static clearLocalStorage = function (storage_key) {
    if (!window.indexedDB) {
      return
    }
    return del(storage_key)
  }

  getSavedDraft = () => {
    if (!window.indexedDB || !this.storage_key) {
      return
    }

    return get(this.storage_key).then((value) => {
      if (value) return JSON.parse(value)
    })
  }

  restoreDraftToEditor = (draft, force = false) => {
    this.saving_drafts = this.canSave()
    this.content_before_restore = this.getValue()
    if (
      !force &&
      this.last_update &&
      new Date(this.last_update * 1000) > new Date(draft.timestamp)
    ) {
      return this.updateDraftStatusMessage(
        this.templateValue('outdated', {
          saved_at: dateFormat(this.time_format, draft.timestamp),
        }),
        draft.timestamp,
        'outdated'
      )
    } else if (draft && draft.content !== '<p></p>') {
      this.setValue(draft.content)
      return this.updateDraftStatusMessage(
        this.templateValue('restored', {
          saved_at: dateFormat(this.time_format, draft.timestamp),
        }),
        draft.timestamp,
        'restored'
      )
    }
  }

  forceRestore = async () => {
    const draft = await this.getSavedDraft()
    return this.restoreDraftToEditor(draft, true)
  }

  discardRestoredDraft = function (e) {
    e.preventDefault()
    if (this.content_before_restore !== null) {
      this.setValue(this.content_before_restore)
      return this.updateDraftStatusMessage('')
    }
  }

  getWordCount = () => {
    return this.getTextValue()
      .split(/\s|\./)
      .filter(function (w) {
        return w.length > 0
      }).length
  }

  updateWordCount = () => {
    var minutes, seconds, word_count, words_per_minute, words_per_second
    if (this.ace) {
      return
    }
    words_per_minute = 200
    words_per_second = words_per_minute / 60.0
    word_count = this.getWordCount()
    seconds = Math.round(word_count / words_per_second)
    minutes = Math.floor(seconds / 60).toString()
    seconds = (seconds % 60).toString()
    if (seconds.length === 1) {
      seconds = '0' + seconds
    }
    word_count = word_count.toString()
    if (!this.hide_status_word) {
      return (this.status_words[0].innerHTML = this.templateValue('words', {
        word_count: word_count,
        minutes: minutes,
        seconds: seconds,
      }))
    }
  }

  updateDraftStatusMessage = (message, timestamp, key) => {
    let element,
      update = true
    if (this.status_container) {
      element = this.status_container.find('.draft')[0]
      if (element) {
        let previousDraftTimestamp = element.dataset.previousDraftTimestamp
        let previousKey = element.dataset.previousKey
        if (
          previousDraftTimestamp &&
          key === previousKey &&
          previousDraftTimestamp > timestamp
        ) {
          update = false
        }
      }
    } else {
      element = this.status_draft[0]
    }
    if (update) {
      if (timestamp) {
        element.dataset.previousDraftTimestamp = timestamp
      }
      element.dataset.previousKey = key
      element.innerHTML = message
    }
    return element.innerHTML
  }

  templates = {
    status:
      "<div class='text-editor-meta'><span class='words'></span><span class='draft'></span></div>",
    status_ace:
      "<div class='text-editor-meta'><span class='draft'></span></div>",
    words:
      '<strong>{%$word_count%}</strong> words. Reading time: <strong>{%$minutes%}:{%$seconds%}</strong>.',
    draft:
      '&nbsp; <i class="fa-solid fa-check"></i> Draft saved at {%$saved_at%}',
    restored:
      "<span class='restored-draft'>&nbsp; Restored draft from {%$saved_at%} (<a href='#' class='discard admin'>Undo</a>)</span>",
    outdated:
      "<span class='restored-draft'>&nbsp; Draft from {%$saved_at%} is available, but is older than current content (<a href='#' class='discard admin'>Discard</a>/<a href='#' class='restore admin'>Restore</a>)</span>",
  }

  stringReplace = (string, obj) => {
    return string.replace(/{%\$(\w+)%}/g, (match, key) => obj[key])
  }

  templateValue = (key, replaceObject) => {
    let str = this.templates[key]
    return this.stringReplace(str, replaceObject)
  }
}

window.registerManager(
  LocalDraftManager,
  'LocalDraftManager',
  '[data-local-draft-storage-key]'
)

const clearLocalStorage = () => {
  var i, len, results, storage_key, storage_keys
  if ((storage_keys = $('body').data('clear-local-storage'))) {
    results = []
    for (i = 0, len = storage_keys.length; i < len; i++) {
      storage_key = storage_keys[i]
      results.push(LocalDraftManager.clearLocalStorage(storage_key))
    }
    return results
  }
}

if (typeof window.Turbolinks !== 'undefined' && window.Turbolinks !== null) {
  $(document).on('page:change', clearLocalStorage)
} else {
  $(clearLocalStorage)
}
