export function dateFormat(format, timestamp, capitalize) {
  function pad(number) {
    return number.toString().replace(/^([0-9])$/, '0$1')
  }

  var date = new Date(timestamp),
    // get the basic time values
    hours = date.getHours(),
    day = date.getDay(),
    dayOfMonth = date.getDate(),
    month = date.getMonth(),
    fullYear = date.getFullYear(),
    langWeekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    langMonths = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    // list all format keys
    replacements = {
      // Day
      a: langWeekdays[day].substr(0, 3), // Short weekday, like 'Mon'
      A: langWeekdays[day], // Long weekday, like 'Monday'
      d: pad(dayOfMonth), // Two digit day of the month, 01 to 31
      e: dayOfMonth, // Day of the month, 1 through 31

      // Week (none implemented)

      // Month
      b: langMonths[month].substr(0, 3), // Short month, like 'Jan'
      B: langMonths[month], // Long month, like 'January'
      m: pad(month + 1), // Two digit month number, 01 through 12

      // Year
      y: fullYear.toString().substr(2, 2), // Two digits year, like 09 for 2009
      Y: fullYear, // Four digits year, like 2009

      // Time
      H: pad(hours), // Two digits hours in 24h format, 00 through 23
      I: pad(hours % 12 || 12), // Two digits hours in 12h format, 00 through 11
      l: hours % 12 || 12, // Hours in 12h format, 1 through 12
      M: pad(date.getMinutes()), // Two digits minutes, 00 through 59
      p: hours < 12 ? 'AM' : 'PM', // Upper case AM or PM
      P: hours < 12 ? 'am' : 'pm', // Lower case AM or PM
      S: pad(date.getSeconds()), // Two digits seconds, 00 through  59
    }

  // do the replaces
  for (var key in replacements) {
    format = format.replace('%' + key, replacements[key])
  }

  // Optionally capitalize the string and return
  return capitalize
    ? format.substr(0, 1).toUpperCase() + format.substr(1)
    : format
}
